// ハンバーガーメニュー
const body = document.getElementsByTagName("body");
const hamburger = document.getElementById("hamburger");
const headerNav = document.querySelector(".header .c-nav");

if (window.innerWidth <= 768) {
  headerNav.classList.add("dNone");
}

hamburger.addEventListener("click", function () {
  body[0].classList.toggle("is-open");
  if (body[0].classList.contains("is-open") == true) {
    headerNav.classList.remove("dNone");
    setTimeout(function () {
      headerNav.classList.add("fadein");
    }, 1);
  } else {
    headerNav.classList.remove("fadein");
    setTimeout(function () {
      headerNav.classList.add("dNone");
    }, 600);
  }
});

// ページ内リンクの遷移時にハンバーガーメニューを閉じる
const navListItems = document.querySelectorAll(".c-nav__list-item");
navListItems.forEach(function (item) {
  item.addEventListener("click", function () {
    body[0].classList.remove("is-open");
    if (headerNav.classList.contains("fadein")) {
      headerNav.classList.remove("fadein");
      setTimeout(function () {
        headerNav.classList.add("dNone");
      }, 600);
    }
  });
});

// フェードイン
let fadeInTarget = document.querySelectorAll(".fade-in");
window.addEventListener("scroll", () => {
  for (let i = 0; i < fadeInTarget.length; i++) {
    const rect = fadeInTarget[i].getBoundingClientRect().top;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const offset = rect + scroll;
    const windowHeight = window.innerHeight; // 現在のブラウザの高さ
    if (scroll > offset - windowHeight + 200) {
      fadeInTarget[i].classList.add("scroll-in");
    }
  }
});

// ヘッダーの背景色
document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelector(".header");

  window.addEventListener("scroll", function () {
    if (window.scrollY > 50) {
      header.classList.add("is-scroll");
    } else {
      header.classList.remove("is-scroll");
    }
  });
});
